<script>
import HrbrButton from '@/components/ui/HrbrButton.vue';
import { CHECK_TYPE_INPUTS } from '@/config/agreement-editor.js';
import { useHarbourStore } from '@/stores/harbour-store';
import { useLibraryStore } from '@/stores/library-store';


export default {
  components: {
    HrbrButton,
  },
  setup() {
    const harbourStore = useHarbourStore();
    const libraryStore = useLibraryStore();
    return {
      harbourStore,
      libraryStore,
    };
  },
  data() {
    return {
      row: null,
      assetsTableDataJsonDetailsShowAllItems: false,
      signerData: {},
      button1: {
        text: 'Download agreement PDF',
        iconLeft: 'fal fa-file-pdf',
      },
      button2: {
        text: 'Download audit trail',
        iconLeft: 'fal fa-file-pdf',
      },
      context: null,
      timeline: {},
      isLoading: false,
      checkTypeInputs: CHECK_TYPE_INPUTS,
      downloadAgreementButton: { text: 'Document', styleType: 'secondary', iconRight: 'fa-download' },
      downloadAuditTrailButton: { text: 'Audit trail', styleType: 'secondary', iconRight: 'fa-download' }
    };
  },
  async created() {
    this.row = this.params.data;
    this.context = await this.getAssetContextSource();
    this.timeline = this.context?.agreementtimelinejson || {};
    this.getSignerData();
    await this.libraryStore.loadVerificationsResults();
  },
  methods: {
    async getAssetContextSource() {
      const { row, libraryStore, harbourStore } = this;

      const contexts = this.libraryStore.loadedContexts;
      const existingContext = contexts.find((context) => context.id === row.id);

      if (existingContext) {
        row.context_source = existingContext.context.context_source;
        return existingContext.context.context_source;
      }

      this.isLoading = true;

      const result = await libraryStore.getContextSource(row.id);
      const asset = harbourStore.myAssets.find((asset) => asset.id === row.id);

      if (!asset) return;

      asset.context_source = result;
      row.context_source = result.context_source;

      this.isLoading = false;

      return row.context_source;
    },
    getSignerData() {
      const auditTrail = this.context?.agreementtimelinejson;
      const itemValues = this.context?.agreementitemvaluesjsonarray;
      if (!auditTrail || !itemValues) return;

      const email = itemValues.find((d) => d.itemspeciallinkedvalue === 'EMAIL')?.itemvalue || '';
      const name = itemValues.find((d) => d.itemspeciallinkedvalue === 'NAME')?.itemvalue || '';
      const signedDate = auditTrail.agreementcompleted_epochms;

      this.signerData.email = email;
      this.signerData.name = name;
      this.signerData.signedDate = signedDate;
      this.signerData.esignConsent = itemValues.find(
        (item) => item.itemrecordlabel === 'E-sign consent',
      );
    },
    downloadAgreement() {
      const url = `/agreepdf/${this.row.id}/${this.row.file_name.replace('.png', '.pdf')}`;
      const filename = `${this.row.name}.pdf`;
      this.handleDownload(url, filename);
    },
    downloadAuditTrail() {
      const url = `/audittrail/${this.context.agreementreferenceid}`;
      const filename = `${this.row.name}-audit-trail.pdf`;
      this.handleDownload(url, filename);
    },
    handleDownload(url, filename) {
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      link.dispatchEvent(new MouseEvent('click'));
      this.toastConfirmation();
    },
    toastConfirmation() {
      this.$buefy.toast.open({
        message: 'Starting download...',
        position: 'is-bottom',
        duration: 3500,
      });
    },
    inputFocusSelectAll() {},
  },

  computed: {
    getCustomItems() {
      const items = this.context?.agreementcustominputsjson?.custominputs;
      if (!items) return;
      return items.filter((item) => ['TEXTINPUT', 'SELECT', 'HTMLINPUT'].includes(item.itemtype));
    },
    hasMultiParty() {
      const multiPartyResults = !!this.timeline?.agreementmultipartyresults;
      const multiParty = !!this.timeline?.agreementmultipartyresults;
      const multiPartyLength = !!this.timeline?.agreementmultipartyresults?.length;
      return multiPartyResults && multiParty && multiPartyLength;
    },
    getMultiSignatoryMapUrl() {
      let placesArray = this.timeline?.agreementmultipartyresults;
      let latLonArray = [];
      placesArray.forEach(function (d) {
        let geoLatLong = d.agreementlinkopened_geo.filter(function (d) {
          return d.header == 'X-AppEngine-CityLatLong';
        })[0];
        if (geoLatLong.value != null && geoLatLong.value != 'ZZ') {
          latLonArray.push(geoLatLong.value);
        }
      });
      if (latLonArray.length == 0) {
        return null;
      }
      let staticMarkersStr = '&markers=' + latLonArray.join('&markers=');
      let interactiveMarkersStr = '/' + latLonArray.join('/');
      let staticMapsUrl =
        'https://maps.google.com/maps/api/staticmap?size=275x165' +
        staticMarkersStr +
        '&key=AIzaSyA3XoWBzP3mlmLw_byG22Vx2tD6B44CdRY';
      let interactiveMapUrl = 'https://www.google.com/maps/dir/' + interactiveMarkersStr;
      return {
        staticmapurl: staticMapsUrl,
        interactivemapsurl: interactiveMapUrl,
      };
    },
  },
};
</script>

<template>
  <div class="cell details-container-bkg" ref="detailsContainer">
    <div class="details-inner-wrapper" v-if="context">
      <!-- LEFT SIDE -->
      <div class="details-column left">
        <div class="details-title">Details</div>

        <div class="signer-area">
          <div class="field-area" v-if="signerData?.name">
            <div class="signer-field-label name">Name</div>
            <div class="signer-value">
              <i class="fal fa-user"></i>
              <span>{{ signerData?.name }}</span>
            </div>
          </div>
          <div class="field-area" v-if="signerData?.email">
            <div class="signer-field-label email">Email</div>
            <div class="signer-value">
              <i class="fal fa-envelope"></i>
              <span>{{ signerData?.email }}</span>
            </div>
          </div>
        </div>

        <div class="signer-area">
          <div v-if="!!context?.agreementcustominputsjson" class="field-area">
            <div
              v-for="customInputItem in getCustomItems"
              :key="customInputItem.itemid"
              class="field-area">
              <div class="signer-field-label">
                {{ customInputItem.itemrecordlabel }}
              </div>

              <!-- CI: HTMLINPUT -->
              <div
                class="signer-value"
                v-if="customInputItem.itemtype === 'HTMLINPUT'"
                @click="inputFocusSelectAll">
                <i class="fal fa-pen"></i>
                {{ getTextFromHtmlString(customInputItem.itemlinkvalue) }}
              </div>

              <div v-else class="signer-value" @click="inputFocusSelectAll">
                <i class="fal fa-angle-right"></i>
                <span>{{ customInputItem.itemlinkvalue }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="signer-area">
          <div class="field-area" v-if="row.context_source?.importnotesourceurl">
            <div class="signer-field-label">Content source</div>
            <div class="signer-value">
              <i class="fal fa-globe"></i>
              <span>{{ row.context_source.importnotesourceurl || '' }}</span>
            </div>
          </div>
        </div>

        <div v-if="!!row.context_source?.agreementitemvaluesjsonarray" class="signer-area">
          <!-- agree link: special linked value agreement signature -->
          <div v-if="row.context_source['agreementitemvaluesjsonarray'] != undefined">
            <!-- agreement label -->
            <b-field label="Agreement"></b-field>
            <!-- agreement title -->
            <div
              class="agreementTitle"
              v-if="
                getNonEmptyJsonValuesByKeySearch(row.context_source, 'agreementtitle').length > 0
              ">
              {{ row.context_source.agreementtitle }}
            </div>
            <!-- agreement signature image -->
            <b-field
              class="assetDetailsFigureInput"
              v-for="inputItem in row.context_source['agreementitemvaluesjsonarray'].filter(
                function (d) {
                  return (
                    d.itemspeciallinkedvalue == 'SIGNATURE' &&
                    d.itemvalue != '' &&
                    d.itemvalue != null &&
                    d.itemvalue != undefined
                  );
                },
              )"
              :key="inputItem.itemid">
              <img class="assetDetailsFigureSignatureInput" :src="inputItem.itemvalue" />
            </b-field>
          </div>

          <div
            v-for="(inputItem, index) in row.context_source.agreementitemvaluesjsonarray
              .filter((inputItem) => inputItem.itemtype !== 'X')
              .filter(function (d, i) {
                return !['NAME', 'EMAIL', 'SIGNATURE'].includes(d.itemspeciallinkedvalue);
              })"
            :key="inputItem.itemid">
            <div
              class="field-area"
              v-show="
                !!inputItem.itemrecordlabel &&
                !!inputItem?.itemvalue &&
                inputItem.itemtype != 'UPLOAD'
              "
              v-if="index < 3 || assetsTableDataJsonDetailsShowAllItems">
              <div class="signer-field-label">
                {{ inputItem.itemrecordlabel }}
              </div>

              <!-- CONTRIBUTOR: TEXTINPUT -->
              <div
                v-if="inputItem.itemtype === 'TEXTINPUT' && !!inputItem.itemvalue"
                @click="inputFocusSelectAll"
                :icon-pack="inputItem.itemiconpack"
                :icon="inputItem.itemicon"
                class="signer-value">
                {{ inputItem.itemvalue }}
              </div>
              <p
                class="control agreementCustomInputsFieldSuffix"
                v-if="!!inputItem.itemsuffixvalue && !!inputItem.itemsuffixvalue.trim()">
                {{ inputItem.itemsuffixvalue }}
              </p>

              <div
                v-if="inputItem.itemtype === 'PARAGRAPHINPUT' && !!inputItem.itemvalue"
                @click="inputFocusSelectAll"
                class="signer-value">
                <i :class="inputItem.itemiconpack + inputItem.itemicon"></i>
                {{ inputItem.itemvalue }}
              </div>

              <!-- CONTRIBUTOR: HTMLINPUT -->
              <div
                v-if="inputItem.itemtype === 'HTMLINPUT' && !!inputItem.itemvalue"
                @click="inputFocusSelectAll">
                <i class="fal fa-pen"></i>
                {{ getTextFromHtmlString(inputItem.itemvalue) }}
              </div>

              <!-- CONTRIBUTOR: SELECT -->
              <b-input
                v-if="inputItem.itemtype == 'SELECT' && inputItem.itemvalue != ''"
                @click="inputFocusSelectAll()"
                :icon-pack="inputItem.itemiconpack"
                :icon="inputItem.itemicon"
                :value="inputItem.itemvalue"
                readonly></b-input>

              <!-- CONTRIBUTOR: CHECKBOX -->
              <span
                v-if="inputItem.itemtype == 'CHECKBOX' || inputItem.itemtype == 'CHECKBOXINPUT'">
                <b-tag v-if="(inputItem.itemvalue + '').toUpperCase() == 'YES'" type="is-light">
                  <i class="fal fa-check"></i>
                  &nbsp;&nbsp; Yes
                </b-tag>
                <b-tag v-if="(inputItem.itemvalue + '').toUpperCase() != 'YES'" type="is-light">
                  {{ inputItem.itemvalue === '' ? 'Unchecked' : inputItem.itemvalue }}
                </b-tag>
              </span>

              <!-- CONTRIBUTOR: YESNO -->
              <span v-if="inputItem.itemtype == 'YESNO' && inputItem.itemvalue != ''">
                <!-- yes (array) -->
                <b-tag
                  v-if="
                    Array.isArray(inputItem.itemvalue) == true &&
                    inputItem.itemvalue?.length > 0 &&
                    (inputItem.itemvalue[0] + '').toUpperCase() == 'YES'
                  "
                  type="is-light"
                  class="detailsTag">
                  <i class="fal fa-check"></i>
                  &nbsp;&nbsp; Yes
                </b-tag>
                <!-- yes (str) -->
                <b-tag
                  v-else-if="
                    typeof inputItem.itemvalue === 'string' &&
                    (inputItem.itemvalue + '').toUpperCase() == 'YES'
                  "
                  type="is-light"
                  class="detailsTag">
                  <i class="fal fa-check"></i>
                  &nbsp;&nbsp; Yes
                </b-tag>
                <!-- no (array) -->
                <b-tag
                  v-else-if="
                    Array.isArray(inputItem.itemvalue) == true &&
                    inputItem.itemvalue &&
                    inputItem.itemvalue.length > 0 &&
                    (inputItem.itemvalue[0] + '').toUpperCase() == 'YES'
                  "
                  type="is-light"
                  class="detailsTag">
                  No
                </b-tag>
                <!-- no (str) -->
                <b-tag
                  v-else-if="
                    typeof inputItem.itemvalue === 'string' &&
                    (inputItem.itemvalue + '').toUpperCase() == 'NO'
                  "
                  type="is-light"
                  class="detailsTag">
                  No
                </b-tag>
                <!-- other (array) -->
                <b-tag
                  v-else-if="Array.isArray(inputItem.itemvalue) == true"
                  type="is-light"
                  class="detailsTag">
                  {{ inputItem.itemvalue?.join(', ') }}
                </b-tag>
                <!-- other (str) -->
                <b-tag v-else type="is-light" class="detailsTag">
                  {{ inputItem.itemvalue }}
                </b-tag>
              </span>

              <!-- CONTRIBUTOR: SIGNATUREINPUT -->
              <span v-if="inputItem.itemtype === 'SIGNATUREINPUT' && !!inputItem.itemvalue">
                <img class="assetDetailsFigureSignatureInput" :src="inputItem.itemvalue" />
              </span>

              <!-- Verifications -->
              <span v-if="checkTypeInputs.includes(inputItem.itemtype)">
                {{inputItem.itemvalue}}
              </span>

            </div>
          </div>

          <div
            v-if="
              row.context_source['agreementitemvaluesjsonarray'].filter(function (d, i) {
                return ['NAME', 'EMAIL', 'SIGNATURE'].includes(d.itemspeciallinkedvalue) == false;
              }).length > 3
            ">
            <b-field>
              <b-button
                type="is-light"
                size="is-small"
                @click="
                  assetsTableDataJsonDetailsShowAllItems = !assetsTableDataJsonDetailsShowAllItems
                ">
                <b-icon
                  pack="fal"
                  :icon="
                    assetsTableDataJsonDetailsShowAllItems ? 'angle-up' : 'angle-down'
                  "></b-icon>
                <span>{{ assetsTableDataJsonDetailsShowAllItems ? 'Less' : 'More' }}</span>
              </b-button>
            </b-field>
          </div>
        </div>
      </div>

      <!-- RIGHT SIDE -->
      <div class="details-column right">
        <div class="timeline-wrapper">
          <div class="details-title">Timeline</div>

          <div class="timeline-container">
            <div class="timeline-right">
              <div class="timeline-item timeline-item--start">
                <div class="timeline-icon-text">
                  <div class="timeline-start">Start</div>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-icon"><i class="fal fa-plus"></i></div>
                <div class="audit-item-date">
                  {{
                    harbourStore.formatUnixTimestamp(timeline.agreementlinkcreated_createdepochms)
                  }}
                </div>
                <div class="audit-item-title">
                  <span>Agreement link created</span>
                  <span class="title-link">
                    <a :href="`https://hrbr.co/${context.agreedisplayid}`" target="_blank">
                      <i class="fal fa-link"></i>
                    </a>
                  </span>
                </div>
                <div class="audit-item-details">
                  <div class="audit-item-details-left">Added by:</div>
                  <div class="audit-item-details-right">
                    {{ timeline.agreementlinkcreated_creatoremail }}
                  </div>
                </div>
              </div>

              <div v-if="hasMultiParty">
                <div
                  class="timeline-item"
                  v-for="(multiPartySubmit, index) in timeline?.agreementmultipartyresults"
                  :key="index">
                  <div class="timeline-icon"><i class="fal fa-signature"></i></div>
                  <div class="audit-item-date">
                    {{
                      harbourStore.formatUnixTimestamp(multiPartySubmit.agreementcompleted_epochms)
                    }}
                  </div>
                  <div class="audit-item-title">
                    <span class="mr-2">Signed:</span>
                    <span>{{ multiPartySubmit.agreementlinkopened_fullname }}</span>
                  </div>
                  <div class="audit-item-details">
                    <div class="audit-item-details-left">Location:</div>
                    <div class="audit-item-details-right">
                      {{
                        multiPartySubmit.agreementlinkopened_geo.filter(function (d) {
                          return d.header == 'X-AppEngine-City';
                        })[0].value
                      }}
                    </div>
                  </div>
                  <div
                    v-if="
                      getMultiSignatoryMapUrl &&
                      index === timeline?.agreementmultipartyresults.length - 1
                    ">
                    <a :href="getMultiSignatoryMapUrl.interactivemapsurl" target="_blank">
                      <img
                        class="timelinemapmultimarker"
                        :src="getMultiSignatoryMapUrl.staticmapurl" />
                    </a>
                  </div>
                </div>
              </div>

              <div class="timeline-item" v-else>
                <div class="timeline-icon"><i class="fal fa-signature"></i></div>
                <div class="audit-item-date">
                  {{
                    harbourStore.formatUnixTimestamp(timeline.agreementlinkopened_epochms)
                  }}
                </div>
                <div class="audit-item-title">
                  <span class="mr-2">Agreement link opened</span>
                </div>
                <div class="audit-item-details">
                  <div class="audit-item-details-left">Recipient:</div>
                  <div class="audit-item-details-right">
                    <span>{{
                      context.agreementitemvaluesjsonarray?.filter(
                        (d) => d.itemspeciallinkedvalue == 'EMAIL',
                      )[0]?.itemvalue || ''
                    }}</span>
                  </div>
                </div>
                <div class="audit-item-details">
                  <div class="audit-item-details-left">Location:</div>
                  <div class="audit-item-details-right">
                    <span>{{
                      timeline.agreementlinkopened_geo.filter(function (d) {
                        return d.header == 'X-AppEngine-City';
                      })[0].value
                    }}</span>
                  </div>
                </div>

                <div v-if="!!timeline && timeline?.agreementlinkopened_geo">
                  <a
                    v-if="
                      timeline.agreementlinkopened_geo.filter(function (d) {
                        return d.header == 'X-AppEngine-CityLatLong';
                      }).length == 1 &&
                      timeline.agreementlinkopened_geo.filter(function (d) {
                        return d.header == 'X-AppEngine-CityLatLong';
                      })[0]?.value != null
                    "
                    :href="
                      'https://www.google.com/maps/place/location/@' +
                      timeline.agreementlinkopened_geo.filter(function (d) {
                        return d.header == 'X-AppEngine-CityLatLong';
                      })[0]?.value
                    "
                    target="_blank">
                    <img
                      class="timelinemap"
                      :src="
                        'https://maps.googleapis.com/maps/api/staticmap?center=' +
                        timeline.agreementlinkopened_geo.filter(function (d) {
                          return d.header == 'X-AppEngine-CityLatLong';
                        })[0].value +
                        '&size=200x200&key=AIzaSyA3XoWBzP3mlmLw_byG22Vx2tD6B44CdRY&zoom=11'
                      " />
                  </a>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-icon is-complete"><i class="fal fa-check"></i></div>
                <div class="audit-item-date">
                  {{
                    harbourStore.formatUnixTimestamp(timeline?.agreementcompleted_epochms)
                  }}
                </div>
                <div class="audit-item-title">
                  <span class="mr-2">Agreement Completed</span>
                </div>
                <div class="audit-item-details">
                  <div class="audit-item-details-left">Recipient:</div>
                  <div class="audit-item-details-right">
                    <span>{{
                      context.agreementitemvaluesjsonarray?.filter(
                        (d) => d.itemspeciallinkedvalue == 'EMAIL',
                      )[0]?.itemvalue || ''
                    }}</span>
                  </div>
                </div>
                <div class="audit-item-details">
                  <div class="audit-item-details-left">Location:</div>
                  <div class="audit-item-details-right">
                    <span>{{
                      timeline.agreementlinkopened_geo.filter(function (d) {
                        return d.header == 'X-AppEngine-City';
                      })[0]?.value
                    }}</span>
                  </div>
                </div>

                <div v-if="!!timeline && timeline?.agreementlinkopened_geo">
                  <a
                    v-if="
                      timeline.agreementlinkopened_geo.filter(function (d) {
                        return d.header == 'X-AppEngine-CityLatLong';
                      }).length == 1 &&
                      timeline.agreementlinkopened_geo.filter(function (d) {
                        return d.header == 'X-AppEngine-CityLatLong';
                      })[0].value != null
                    "
                    :href="
                      'https://www.google.com/maps/place/location/@' +
                      timeline.agreementlinkopened_geo.filter(function (d) {
                        return d.header == 'X-AppEngine-CityLatLong';
                      })[0].value
                    "
                    target="_blank">
                    <img
                      class="timelinemap"
                      :src="
                        'https://maps.googleapis.com/maps/api/staticmap?center=' +
                        timeline.agreementlinkopened_geo.filter(function (d) {
                          return d.header == 'X-AppEngine-CityLatLong';
                        })[0].value +
                        '&size=200x200&key=AIzaSyA3XoWBzP3mlmLw_byG22Vx2tD6B44CdRY&zoom=11'
                      " />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="timeline-buttons">
            <div class="timeline-buttons-wrapper">
              <HrbrButton :button="downloadAgreementButton" @click="downloadAgreement" />
              <HrbrButton :button="downloadAuditTrailButton" @click="downloadAuditTrail" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="loading-container">
      <i class="fal fa-spinner-third fa-spin mr-2"></i>
      <span>Loading audit trail</span>
    </div>
  </div>
</template>

<style scoped>
.title-link {
  margin-left: 5px;
  font-size: 12px;
  color: #666;
  cursor: pointer;
}
.cell {
  height: fit-content;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  background-color: white;
}
.loading-container {
  width: 100%;
  height: 100%;
  min-height: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}
.details-container-bkg {
  background-color: #fafafa !important;
  white-space: normal;
}
.details-inner-wrapper {
  display: flex;
  max-width: 800px;
}
.left {
  min-width: 400px;
  width: 50%;
  max-height: 100%;
  overflow: auto;
}
.signer-value i {
  margin-right: 10px;
}
.signer-value span {
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 1em;
}
.right {
  height: 100%;
  min-height: 100% !important;
  min-width: 400px;
  margin-left: -19px;
  width: 50%;
  display: flex;
  align-items: center !important;
}
/* LEFT SIDE */
.details-title {
  color: rgb(0 0 0 / 70%);
  display: block;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.4px;
  position: relative;
  left: 1px;
  margin-bottom: 15px;
}
.details-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 20px;
  padding-left: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.signer-value {
  display: flex;
  align-items: center;
  width: 284px;
  border-radius: 3px;
  padding: 12px 21px;
  align-items: flex-start;
  border: 1px solid var(--light-gray, #d6dbdf);
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  max-width: 100%;
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
  background-color: white;
}
.field-area {
  padding: 10px 0;
}
.signer-field-label {
  margin-top: 5px;
  color: #000;

  /* Subheading */
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 3px;
}
.signer-value-check i {
  margin-right: 10px;
}

/* RIGHT SIDE */
.timeline-buttons {
  position: absolute;
  right: 50px;
  top: 12px;
}
.timeline-button {
  margin-bottom: 4px;
  display: flex;
  height: 31px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #1b1b1c29;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  transition: all 0.2s ease-in-out 0s;
  width: 110px;
  background: white;
  color: #000000b3;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}
.timeline-buttons i {
  margin: 0 5px;
}
.timeline-button:hover {
  color: black;
  border: 1px solid black;
}
.timeline-container {
  display: flex;
  margin-bottom: 30px;
  margin-left: 30px;
  padding-top: 64px;
}
.timeline-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.timeline-left {
  width: 150px;
  color: #000;

  /* Subheading */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
.timeline-item {
  padding: 0;
  padding-left: 65px;
  border-left: 2px solid var(--harbour-blue, #1a4c8b);
  margin: 0;
  padding-bottom: 20px;
}
.audit-item-title {
  color: #000000d6;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.audit-item-date {
  color: #000;

  /* Supportive Text */
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px; /* 133.333% */
}
.audit-item-details {
  display: flex;
  /* Supportive Text */
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px; /* 133.333% */
  color: #000;
}
.audit-item-details-left {
  margin-right: 5px;
}

.timelinemap {
  border-radius: 50%;
  padding: 10px;
  background-color: white;
  height: 160px;
  width: 160px;
  margin-top: 8px;
}
.timelinemap:hover {
  cursor: pointer;
  background-color: #0000000d;
}
.timelinemapmultimarker {
  border-radius: 3%;
  padding: 10px;
  background-color: white;
  margin-top: 8px;
}
.timelinemapmultimarker:hover {
  cursor: pointer;
  background-color: #0000000d;
}

.timeline-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 11px;
  border: 2px solid var(--harbour-blue, #1a4c8b);
  color: var(--harbour-blue, #1a4c8b);
  position: absolute;
  margin-left: -75.5px;
  background: var(--pale-gray, #f0f4f6);
}
.timeline-icon i {
  font-weight: 800;
}
.timeline-icon-text {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 40px;
  border-radius: 3px;
  font-size: 11px;
  /* border: 2px solid var(--harbour-blue, #1A4C8B); */
  color: var(--harbour-blue, #1a4c8b);
  position: absolute;
  margin-left: -85.5px;
  background: var(--pale-gray, #f0f4f6);
  color: #000;

  /* Subheading */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
.assetDetailsFigureSignatureInput {
  width: 100%;
  height: auto;
  background-color: white;
  border: 1px solid #dbdbdb;
  padding: 14px;
  border-radius: 3px;
}
.timeline-start {
  background-color: #7957d5 !important;
  padding-left: 8px;
  margin: 10px;
  border-radius: 5px;
  padding-right: 8px;
  position: relative;
  top: -6px;
  color: white;
  font-size: 14px;
  font-weight: 500;
}
.timeline-buttons-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
.is-complete {
  background-color: var(--completed, #00d1b2) !important;
  border: 1px solid var(--completed, #00d1b2) !important;
  color: white;
}

@media (max-width: 992px) {
  .details-container-bkg {
    padding: 0 15px;
    position: relative;
  }
  .details-inner-wrapper {
    flex-direction: column;
    padding: 50px 0;
  }
  .details-column {
    width: 100%;
    height: auto;
    min-width: auto;
    min-height: auto;
    max-height: none;
    overflow: initial;
    padding: 0;
    margin: 0;
  }
  .details-column.left {
    margin-bottom: 50px;
  }
  .details-column.right {
    align-items: center !important;
    min-height: auto !important;
  }
  .timeline-wrapper {
    flex-direction: column;
  }
  .timeline-container {
    padding-top: 0;
    margin-left: 25px;
    margin-bottom: 0;
  }
  .timeline-item {
    padding-left: 35px;
  }
  .timeline-icon {
    margin-left: -45.5px;
  }
  .timeline-icon-text {
    margin-left: -55.5px;
  }
}
</style>
