import Vue from 'vue';

export default {
  async copyTemplateToOrg(templateId, payload) {
    const { data } = await Vue.prototype.$harbourData.post(`/api/templates/${templateId}/copy`, {
      created_by: payload.createdBy,
      destination_organization_id: payload.orgId,
      destination_template_group_id: payload.targetOrgTemplateGroupId,
    });
    return data;
  },

  async addAdminToOrg(orgId, payload) {
    const { data } = await Vue.prototype.$harbourData.put(`/api/organizations/${orgId}`, {
      admins: payload.emails,
    });
    return data;
  },
};
