<script setup>
import EmailInput from '@/components/EmailInput.vue';
import HrbrInput from '@/components/ui/HrbrInput.vue';
import internalToolsService from '@/services/internal-tools-service.js';
import { ToastProgrammatic as Toast } from 'buefy';
import { computed, reactive, ref } from 'vue';

const emit = defineEmits(['close']);

const targetOrgId = ref('');
const adminEmails = ref([]);

const isConfirmDisabled = computed(() => {
  return !targetOrgId.value.trim() || adminEmails.value.length === 0;
});

const cancelButton = reactive({
  show: true,
  text: 'Cancel',
  action: () => {
    closeModal();
  },
});

const confirmButton = computed(() => ({
  show: true,
  text: 'Confirm',
  styleType: 'primary',
  disabled: isConfirmDisabled.value,
  action: () => {
    if (!isConfirmDisabled.value) {
      addOrganizationAdmin();
      closeModal();
    }
  },
}));

const closeModal = () => {
  emit('close');
}

const addOrganizationAdmin = async () => {
  try {
    await internalToolsService.addAdminToOrg(targetOrgId.value, {
      emails: adminEmails.value,
    });
    Toast.open({
      message: 'Organization admin was successfully added!',
      type: 'is-success',
      position: 'is-top',
      duration: 3500,
    });
  } catch (e) {
    console.error(e);
    Toast.open({
      message: `Unable to add organization admin - ${e.message}`,
      type: 'is-danger',
      position: 'is-top',
      duration: 3500,
    });
  }
}
</script>

<template>
  <div class="add-admin-modal hrbr-modal modal-card">
    <header class="modal-card-head">
      <h3 class="modal-card-title">
        Add admin to organization
      </h3>
    </header>
    <section class="modal-card-body">
      <div class="select-group">
        <p class="label">Enter users emails</p>
        <EmailInput
          placeholder="Enter emails"
          icon-pack="far"
          icon="envelope"
          ellipsis
          :maxlength="500"
          :has-counter="false"
          maxtags="50"
          :readonly="false"
          :on-paste-separators="[',', ' ']"
          v-model="adminEmails"
          :email-store="adminEmails"
          :results-limit="30"
          :recents-tags-limit="5"
          :show-self-in-recents="true"
        />
      </div>

      <hrbr-input
        v-model="targetOrgId"
        label="Enter target org ID"
      />
    </section>
    <footer class="modal-card-foot">
      <HrbrButton class-name="cancel-btn" :button="cancelButton" />
      <HrbrButton class-name="confirm-btn" :button="confirmButton" />
    </footer>
  </div>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/elements/modal.css";
@import '@/assets/css/elements/select.css';

.select-group {
  .label {
    color: #49525c;
  }
  > .selected, .dropdown {
    border-radius: 5px;
    &:hover {
      border-color: rgba(47, 90, 137, 0.6);
    }
  }
}

.modal-card-foot {
  justify-content: flex-end;
  
  .confirm-btn {
    margin-left: 10px;
  }
}
</style>