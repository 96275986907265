<script setup>
import { useBillingStore } from '@/pages/Settings/stores/billing-store.js';
import { PLAN_TYPE } from '@/pages/Settings/stores/constants';
import { computed } from 'vue';
import { useRouter } from 'vue-router/composables';
import harbourLogoImg from '../../assets/icons/harbour-logo-blue.svg';

const billingStore = useBillingStore();
const router = useRouter();

const logoUrl = computed(() => {
  if (billingStore.harbourStore.logoSrc) return billingStore.harbourStore.logoSrc;
  return harbourLogoImg;
});

const currentPlan = computed(() => billingStore.getSubscriptionType);
const isSubscriptionActive = computed(() => billingStore.isSubscriptionActive);

const handleLogoClick = () => {
  router.push({ name: 'dashboard' });
};
</script>

<template>
  <div class="hrbr-sidebar-nav__logo-container" @click="handleLogoClick">
    <img :src="logoUrl" alt="Logo" class="hrbr-sidebar-nav__logo" />
    <div v-if="(currentPlan === PLAN_TYPE.STARTER || !isSubscriptionActive) && billingStore.subscriptionPlanLoaded"
      class="hrbr-sidebar-nav__badge hrbr-sidebar-nav__badge--starter">
      <span class="hrbr-sidebar-nav__badge-text">Starter plan</span>
    </div>
  </div>
</template>

<style scoped>
.hrbr-sidebar-nav__logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  margin-bottom: 16px;
  cursor: pointer;
}

.hrbr-sidebar-nav__logo {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.hrbr-sidebar-nav__badge {
  margin-top: -5px;
  padding: 2px 8px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hrbr-sidebar-nav__badge--starter {
  background: var(--gold-gradient, linear-gradient(261deg, #FFDB66 0%, #FFB200 104.08%));
}

.hrbr-sidebar-nav__badge-text {
  font-size: 10px;
  font-weight: 700;
  color: #333;
}
</style>
