<script setup>
import Vue from 'vue';
import HrbrAdvancedSearchModal from '@/pages/AdvancedSearch/components/Modals/HrbrAdvancedSearchModal.vue';
import HrbrSearchMatch from './HrbrSearchMatch.vue';
import { onMounted, ref, computed } from 'vue';
import { useHarbourStore } from '@/stores/harbour-store';
import { useSearchStore } from './stores/search-store';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import { useCkeditorStore } from '../Ckeditor/stores/ckeditor-store';
import { useCommands } from './composables/use-commands';
import { useRouter } from 'vue-router/composables';
import { ModalProgrammatic as Modal, ToastProgrammatic as Toast } from 'buefy';
import { textSanitize, readFileAsBinaryString } from '@/utils/helpers/functions';
import searchApiService from '@/services/search-api-service';
import debounce from '@/utils/debounce';
import SparkMD5 from 'spark-md5';
import { getCurrentInstance } from 'vue';
import { useDraftsStore } from '../../pages/Drafts/stores/drafts-store';

const harbourStore = useHarbourStore();
const searchStore = useSearchStore();
const templatesStore = useTemplatesStore();
const ckeditorStore = useCkeditorStore();
const draftsStore = useDraftsStore();
const router = useRouter();
const instance = getCurrentInstance();

const { commands, defaultCommands, adminCommands } = useCommands();

const autocompleteSearch = ref(null);
const searchData = ref([...defaultCommands]);
const searchInput = ref('');

const agreementIdFilter = ref(null);
const agreementFolderIdFilter = ref(null);
const agreementCreatorEmailFilter = ref(null);
const agreementCreateDatesFilter = ref(null);

const isAutoPopulateInProgress = ref(false);

const isAdminMode = ref(false);

const agreementFilters = ref({
  text_filter: {},
  input_filters: [],
  attachment_filters: [],
  tags_filter: {},
  creation_date_filter: {},
  is_admin_access: false,
  page_num: 1,
});

const activeFilters = ref(0);
const totalMatchCount = ref(-1);
const totalMatchCountHold = ref(-1);
const searchResultsMsg = ref('Searching...');

const isOnFocus = ref(false);
const isSearching = ref(false);
const isRecentResults = ref(false);
const isBump = ref(false);

const filterAccessOptions = ref([
  {
    title: 'My view',
    value: 'defaultaccess',
    icon: 'user',
    tooltipLabel: 'Search across my agreements',
  },
  {
    title: 'Admin view',
    value: 'adminaccess',
    icon: 'users-crown',
    tooltipLabel: 'Search across organization agreements',
  },
]);

const showAgreementsButtonText = computed(() => {
  if (totalMatchCount.value > 0) {
    return `Show ${totalMatchCount.value} agreements`;
  }
  if (totalMatchCount.value === 0) {
    return 'No agreements found';
  }
  return 'Show agreements';
});

const isOrgAdmin = computed(() => {
  const roles = harbourStore.contextDict?.auth_roles || [];
  return roles.includes('orgAdmin');
});

const runQuickSearch = async (signal = null) => {
  try {
    const filters = {
      textFilter: agreementFilters.value.text_filter,
      inputFilters: agreementFilters.value.input_filters,
      attachmentFilters: agreementFilters.value.attachment_filters,
      tagsFilter: agreementFilters.value.tags_filter,
      creationDateFilter: agreementFilters.value.creation_date_filter,
      isAdminAccess: agreementFilters.value.is_admin_access,
    };

    const respData = await searchApiService.runQuickSearch(filters, signal);
    const totalCount = respData.totalhitscount;
    totalMatchCountHold.value = totalCount;
  } catch (err) {
    console.log(err);
  }
};

let searchResultsController = null;
const loadSearchResults = async (isInitialLoading = false) => {
  if (!isInitialLoading) searchResultsController?.abort();

  try {
    isSearching.value = true;

    searchResultsController = new AbortController();
    const { signal } = searchResultsController;

    totalMatchCount.value = -1;
    totalMatchCountHold.value = -1;
    searchResultsMsg.value = 'Searching...';
    if (!isInitialLoading) searchData.value = [];

    const searchQuery = searchInput.value.trim().toLowerCase();
    const pageName = location.pathname.replaceAll('/', '');

    // Admin actions on
    if (searchQuery === 'captain') {
      isAdminMode.value = true;
      searchData.value = [
        ...searchData.value,
        ...adminCommands
      ];
    } else {
      isAdminMode.value = false;
    }

    updateSearchMsgBasedOnQuery(searchQuery);
    buildAgreementsFilters();
    runQuickSearch(signal);

    const respData = await searchApiService.getSearchQueryMatches({
      queryText: searchQuery,
      filters: agreementFilters.value,
      numActiveFilters: activeFilters.value,
      pageName,
      signal,
    });

    const resultsQuery = respData.querytext;
    const isEmptyQuery = respData.isemptysearchquery;
    let searchResults = respData.results ?? [];

    if (isAdminMode.value) {
      searchResults = [
        ...searchData.value,
        ...searchResults,
      ]
    }

    // filter legacy drafts
    searchResults = searchResults.filter((i) => !i.id?.startsWith('AGREEMENTDRAFTID'));

    if (isEmptyQuery) {
      searchResults = searchResults.slice(0, 3);
      searchResults = [...defaultCommands, ...searchResults];
    } else {
      const commands = getFilteredCommands();
      const topCommands = commands.slice(0, 3);
      searchResults = [...topCommands, ...searchResults];
    }

    searchData.value = searchResults;
    isRecentResults.value = isEmptyQuery;
    updateSearchMsgBasedOnResult(searchResults, resultsQuery);
  } catch (err) {
    console.log(err);
  } finally {
    isSearching.value = false;
  }
};

const getFilteredCommands = () => {
  const searchQuery = searchInput.value.trim().toLowerCase();
  if (searchQuery && searchQuery.length > 1) {
    const templateCommands = buildCommandsFromTemplates();
    const allCommands = [...commands, ...templateCommands];
    return allCommands.filter((command) => {
      const title = command.title.toLowerCase();
      return title.includes(searchQuery);
    });
  }
  return commands;
};

const buildCommandsFromTemplates = () => {
  const templates = templatesStore.myTemplates;
  if (!templates.length) return [];
  const commands = templates.map((template) => ({
    title: `Start ${template.title}`,
    icon: 'file-contract',
    type: 'command',
    handler: () => {
      createLink(template);
    },
  }));
  return commands;
};

const createLink = async (template) => {
  const currentFolder = harbourStore.currentFolder;
  if (!currentFolder) return;
  const customInputs = template.custom_input_fields_json;

  let options = {
    agreementId: template.agreement_id,
    lastbrand: true,
  };

  const ckeditorFileId = customInputs?.ckeditoragreementid;
  if (!ckeditorFileId) {
    openAgreementLinkModal(options);
    return;
  }
  let ckeditorCopyResult = await ckeditorStore
    .copyCkeditorTemplateSaveMeta(template)
    .catch(() => null);
  if (!ckeditorCopyResult) return;
  const ckeditorOptions = {
    ckeditorFileId: ckeditorCopyResult.ckeditorFileId,
    ckeditorHtmlData: ckeditorCopyResult.ckeditorHtmlData,
    agreementId: ckeditorCopyResult.agreementId,
    sourceTemplateTitle: template.title,
  };
  options = { ...options, ...ckeditorOptions };
  openAgreementLinkModal(options);
};

const openAgreementLinkModal = ({
  agreementId,
  lastbrand = null,
  ckeditorFileId = null,
  ckeditorHtmlData = null,
  ckeditorAnchors = [],
  sourceTemplateTitle = null,
}) => {
  const props = {
    agreementId,
    lastbrand,
    ckeditorFileId,
    ckeditorHtmlData,
    ckeditorAnchors,
    sourceTemplateTitle,
    parent: null,
  };
  Vue.prototype.$openAgreementLinkModal({ props });
};

const loadSearchResultsDebounced = debounce(() => {
  loadSearchResults();
}, 300);

const buildAgreementsFilters = () => {
  let tempAgreementFilters = {
    text_filter: {},
    input_filters: [],
    attachment_filters: [],
    tags_filter: {},
    creation_date_filter: {},
    is_admin_access: searchStore.accessOption === 'defaultaccess' ? false : true,
    page_num: 1,
  };
  let activeFiltersNum = 0;

  // Agreement type id filter
  if (agreementIdFilter.value) {
    const agreement = templatesStore.myTemplates.find(
      (a) => a.agreement_unique_id == agreementIdFilter.value,
    );
    if (agreement) {
      const tempInputValue = {};
      tempInputValue.filter_name = 'source.agreement_id';
      tempInputValue.filter_index = -1;
      tempInputValue.filter_type = 'agreement_id';
      tempInputValue.filter_value = agreement.agreement_id;
      tempAgreementFilters.input_filters.push(tempInputValue);
    }
    activeFiltersNum += 1;
  }
  // Agreement Creator email filter
  if (agreementCreatorEmailFilter.value) {
    let tempCreatorEmail = {};
    tempCreatorEmail.filter_name = 'creator_email';
    tempCreatorEmail.filter_index = -1;
    tempCreatorEmail.filter_type = 'creator_email';
    tempCreatorEmail.filter_value = agreementCreatorEmailFilter.value;
    tempAgreementFilters.input_filters.push(tempCreatorEmail);
    activeFiltersNum += 1;
  }
  // Agreement folder name filter
  if (agreementFolderIdFilter.value) {
    let tempFolder = {};
    tempFolder.filter_name = 'source.parent_folder_details.unique_id';
    tempFolder.filter_index = -1;
    tempFolder.filter_type = 'parent_folder_id';
    tempFolder.filter_value = agreementFolderIdFilter.value;
    tempAgreementFilters.input_filters.push(tempFolder);
    activeFiltersNum += 1;
  }
  // Create date filter
  if (agreementCreateDatesFilter.value && agreementCreateDatesFilter.value.length === 2) {
    const [fromDate, toDate] = agreementCreateDatesFilter.value;
    if (fromDate && toDate) {
      const fromDateTime = new Date(fromDate);
      const toDateTime = new Date(toDate);
      const fromDateStr = `${fromDateTime.getFullYear()}-${fromDateTime.getMonth() + 1}-${fromDateTime.getDate()}`; // prettier-ignore
      const toDateStr = `${toDateTime.getFullYear()}-${toDateTime.getMonth() + 1}-${toDateTime.getDate()}`; // prettier-ignore
      tempAgreementFilters.creation_date_filter.filter_name = 'source.creation_details.uploader_date_time'; // prettier-ignore
      tempAgreementFilters.creation_date_filter.filter_value = [fromDateStr, toDateStr];
      activeFiltersNum += 1;
    }
  }

  let searchQuery = searchInput.value.trim().toLowerCase();
  let isExactTextSearch = false;
  if (searchQuery && searchQuery !== '') {
    if (searchQuery.includes('"')) {
      searchQuery = searchQuery.replaceAll('"', '');
      isExactTextSearch = true;
    }
    tempAgreementFilters.text_filter.filter_name = 'document.full_asset_text.pdf_paragraph_text';
    let searchQuerySanitize = textSanitize(searchQuery);
    if (/^-?\d+$/.test(searchQuerySanitize)) {
      let searchQueryNumberFormatted = (+searchQuerySanitize).toLocaleString('en');
      searchQuerySanitize = searchQuerySanitize + ' || ' + searchQueryNumberFormatted;
    }
    tempAgreementFilters.text_filter.filter_value = searchQuerySanitize;
    tempAgreementFilters.text_filter.is_exact_match = isExactTextSearch;
    activeFiltersNum += 1;
  }
  agreementFilters.value = tempAgreementFilters;
  activeFilters.value = activeFiltersNum;
};

const applyFilters = () => {
  buildAgreementsFilters();
  saveFiltersLocal();

  searchStore.isDirectResultAccess = false;
  searchStore.loadStoredUserFilters();
  searchStore.getPaginatedDocuments();

  router.push({ name: 'advancedsearch' });
  closeSearch();
};

const searchByFile = () => {
  const fileInputElem = document.createElement('input');
  fileInputElem.type = 'file';
  fileInputElem.hidden = true;
  fileInputElem.setAttribute('multiple', false);

  document.body.append(fileInputElem);

  const handleChange = async (event) => {
    fileInputElem.remove();

    const [file] = event.target.files;
    if (!file) return;

    if (!validateFileForSearch(file)) return;

    const fileName = file.name;
    const fileNameToCompare = fileName.toLowerCase().replace('.jpeg', '.jpg');

    const binaryData = await readFileAsBinaryString(file);
    const spark = new SparkMD5();
    spark.appendBinary(binaryData);
    const attachmentFilterValue = btoa(spark.end(true));

    const agreementFilters = {
      text_filter: {},
      input_filters: [],
      attachment_filters: [],
      tags_filter: {},
      creation_date_filter: {},
      is_admin_access: searchStore.accessOption == 'defaultaccess' ? false : true,
      page_num: 1,
    };
    const tempAttachment = {};
    if (fileNameToCompare.includes('.pdf')) {
      tempAttachment.filter_type = 'document';
    }
    if (fileNameToCompare.includes('.jpg') || fileNameToCompare.includes('.png')) {
      tempAttachment.filter_type = 'image';
    }
    tempAttachment['filter_name'] = 'document.asset_md5';
    tempAttachment['filter_value'] = attachmentFilterValue;
    agreementFilters['attachment_filters'].push(tempAttachment);

    localStorage.setItem('advancedSearchFilters', JSON.stringify(agreementFilters));
    localStorage.setItem('multipleAgreementTextFilter', JSON.stringify(['']));

    router.push({ name: 'advancedsearch' });
    closeSearch();
  };

  fileInputElem.addEventListener('change', handleChange);
  fileInputElem.click();
};

const validateFileForSearch = (file) => {
  const fileNameToCompare = file.name.toLowerCase().replace('.jpeg', '.jpg');

  if (
    !fileNameToCompare.includes('.pdf') &&
    !fileNameToCompare.includes('.jpg') &&
    !fileNameToCompare.includes('.png')
  ) {
    Toast.open({
      message: `The provided file is not a required type (PDF or JPG or PNG). Please try again.`,
      type: 'is-warning',
      position: 'is-top',
      duration: 3500,
    });
    return false;
  }

  const fileSize = file.size;
  if (fileSize < 5) {
    Toast.open({
      message: `The provided file is too small. Please try again with a larger version.`,
      type: 'is-warning',
      position: 'is-top',
      duration: 3500,
    });
    return false;
  }
  if (fileSize < 5) {
    Toast.open({
      message: `The provided file is too small. Please try again with a larger version.`,
      type: 'is-warning',
      position: 'is-top',
      duration: 3500,
    });
    return false;
  }
  if (fileSize > 4000000) {
    Toast.open({
      message: `The provided file is too large. Please try again with a smaller version.`,
      type: 'is-warning',
      position: 'is-top',
      duration: 3500,
    });
    return false;
  }
  return true;
};

const openAdvancedSearchModal = (isMoreFiltersBtn = false) => {
  Modal.open({
    component: HrbrAdvancedSearchModal,
    canCancel: true,
    hasModalCard: true,
    parent: instance.proxy,
    props: {
      searchInput: searchInput.value,
      isMoreFilters: isMoreFiltersBtn,
    },
  });
};

const updateSearchMsgBasedOnQuery = (query) => {
  if (query !== '') {
    searchResultsMsg.value = `<i class="fal fa-spinner fa-pulse"></i>&nbsp;&nbsp;&nbsp;Searching for '${query}'...`;
    return;
  }
  if (activeFilters.value > 0) {
    searchResultsMsg.value = `<i class="fal fa-spinner fa-pulse"></i>&nbsp;&nbsp;Searching for matching results...`;
  } else {
    searchResultsMsg.value = `<i class="fal fa-spinner fa-pulse"></i>&nbsp;&nbsp;Searching for recently added...`;
  }
};

const updateSearchMsgBasedOnResult = (searchResults, resultsQuery) => {
  if (searchResults === 0 && resultsQuery.length === 1) {
    searchResultsMsg.value = '-- type at least two characters for results to appear --';
  } else if (searchResults.length === 0 && resultsQuery.length > 1) {
    searchResultsMsg.value = "-- no results for '" + resultsQuery + "' --";
  } else if (searchResults.length === 0 && activeFilters.value > 0) {
    totalMatchCount.value = 0;
    searchResultsMsg.value = '-- no results for the selected filters --';
  } else {
    searchResultsMsg.value = null;
    if (activeFilters.value > 0) {
      totalMatchCount.value = totalMatchCountHold.value;
    } else {
      totalMatchCount.value = -1;
    }
  }
};

const initOnSearchResultsPage = () => {
  const searchResultsPage = location.href.includes('/advancedsearch');
  if (!searchResultsPage) return;
  agreementFilters.value = searchStore.filters;
  isAutoPopulateInProgress.value = true;
  let dateFilters = agreementFilters.value.creation_date_filter;
  if (Object.keys(dateFilters).length > 0) {
    if ('filter_value' in dateFilters) {
      let fromDateTime = new Date(dateFilters.filter_value[0]);
      let toDateTime = new Date(dateFilters.filter_value[1]);
      agreementCreateDatesFilter.value = [fromDateTime, toDateTime];
    }
  }

  let textFilters = agreementFilters.value.text_filter;
  if (Object.keys(textFilters).length > 0) {
    if ('filter_value' in textFilters) {
      searchInput.value = textFilters.filter_value;
    }
  }

  let inputFilters = agreementFilters.value.input_filters;
  if (inputFilters.length > 0) {
    let agreementId = inputFilters.find((a) => a.filter_name == 'source.agreement_id');
    if (agreementId) {
      let agreement_id = agreementId.filter_value;
      let agreement = templatesStore.myTemplates.find((a) => a.agreement_id == agreement_id);
      agreementIdFilter.value = agreement.agreement_unique_id;
    }
    let agreementFolderNameFilter = inputFilters.find(
      (a) => a.filter_name == 'source.parent_folder_details.unique_id',
    );
    if (agreementFolderNameFilter) {
      let agreement_folder_name = agreementFolderNameFilter.filter_value;
      agreementFolderIdFilter.value = agreement_folder_name;
    }
  }

  if (agreementFilters.value.is_admin_access) {
    searchStore.accessOption = 'adminaccess';
  }
  isAutoPopulateInProgress.value = false;
};

const saveFiltersLocal = () => {
  localStorage.setItem('advancedSearchFilters', JSON.stringify(agreementFilters.value));
  localStorage.setItem('multipleAgreementTextFilter', JSON.stringify([searchInput.value]));
  localStorage.setItem('activeFilters', JSON.stringify(activeFilters.value));
};

const openDraftDocument = (event) => {
  const {id, title, referenceid, uniqueid, type} = event;

  const draft = {
    ckFileId: id,
    agreementId: referenceid,
    name: title,
    id: uniqueid,
    type
  }

  draftsStore.openDraftDocument({
    draft,
    addUrlParams: true,
    parentComponent: instance.proxy,
  });
}

const handleAutocompleteSelect = (event) => {
  const { type, handler, id: itemId, draftuniqueid } = event;

  let strategyKey = type;
  if (type === 'pdf' && draftuniqueid) strategyKey = 'pdfDraft';

  const strategyMap = {
    command: 'command',
    docx: 'draft',
    pdfDraft: 'pdfDraft',
    default: 'asset'
  };
  let strategy = strategyMap[strategyKey] || strategyMap.default;

  const handlers = {
    command: () => {
      handler();
      closeSearch();
    },
    asset: () => {
      const folderId = event.destinationfolderid;
      const query = { search: itemId, folderid: folderId };
      router.push({ name: 'folders', query });
      closeSearch();
    },
    draft: () => {
      openDraftDocument(event);
      closeSearch();
    },
    pdfDraft: () => {
      openDraftDocument(event);
      closeSearch();
    },
    default: () => {
      console.log('not implemented');
    },
  };

  const handlerFunc = handlers[strategy] || handlers.default;
  handlerFunc();
};

const handleMoreFiltersBtnClick = () => {
  saveFiltersLocal();
  openAdvancedSearchModal(true);
  closeSearch();
};

const handleInputsSearchBtnClick = () => {
  openAdvancedSearchModal(false);
  closeSearch();
};

const handleFilterChange = () => {
  loadSearchResultsDebounced();
  focusAutocomplete();
};

const handleShowAgreementsBtnClick = () => {
  applyFilters();
};

const handleAutocompleteFocus = () => {
  isOnFocus.value = true;
};

const handleAutocompleteBlur = () => {
  isOnFocus.value = false;
};

const handleImgPreviewError = (event) => {
  event.target.src = '/src/assets/imgs/placeholder_img.png';
};

const animateSearch = () => {
  isBump.value = true;
  setTimeout(() => {
    isBump.value = false;
  }, 500);
};

const closeSearch = () => {
  searchStore.closeSearch();
};

const focusAutocomplete = () => {
  autocompleteSearch.value.focus();
};

const checkCommand = (type) => {
  return type === 'command';
};

const getFirstCommandIdx = () => {
  return searchData.value.findIndex((i) => i.type === 'command');
};

const getFirstResultItemIdx = () => {
  return searchData.value.findIndex((i) => i.id && i.type !== 'command');
};

const getSearchFoldersList = () => {
  return harbourStore.myFolders.filter((folder) => !folder.id.startsWith('#'));
};

const _agreemntsByTitle = (a, b) => {
  const name1 = a.title.toLowerCase();
  const name2 = b.title.toLowerCase();
  return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
};

const templates = computed(() => {
  return [...templatesStore.myTemplates].sort(_agreemntsByTitle);
});

onMounted(() => {
  animateSearch();
  focusAutocomplete();
  initOnSearchResultsPage();
  loadSearchResults(true);
});
</script>

<template>
  <div class="hrbr-search-modal" @click.self="closeSearch">
    <div class="hrbr-search-modal__content" :class="{ bump: isBump }">
      <div class="hrbr-search">
        <div class="hrbr-search__wrapper">
          <b-autocomplete
            class="hrbr-search__autocomplete"
            v-model.trim="searchInput"
            :data="searchData"
            :field="'title'"
            :placeholder="'Type a command or search for assets'"
            :clear-on-select="true"
            :open-on-focus="true"
            :selectable-header="false"
            :selectable-footer="false"
            icon-pack="fal"
            dropdown-position="bottom"
            ref="autocompleteSearch"
            @typing="loadSearchResultsDebounced"
            @select="handleAutocompleteSelect($event)"
            @focus="handleAutocompleteFocus"
            @blur="handleAutocompleteBlur"
            @keyup.enter.native="applyFilters()">
            <template slot="header">
              <div class="hrbr-search__autocomplete-header">
                <!-- Filter -->
                <div class="hrbr-search__filter level-item" v-if="isOrgAdmin">
                  <div
                    class="hrbr-search__view-type-radio-wrap"
                    v-for="option in filterAccessOptions"
                    :key="option.value">
                    <b-radio-button
                      class="hrbr-search__view-type-radio-btn"
                      :class="
                        option.value === 'adminaccess'
                          ? 'hrbr-search__view-type-radio-btn--admin'
                          : 'hrbr-search__view-type-radio-btn--default'
                      "
                      v-model="searchStore.accessOption"
                      :native-value="option.value"
                      type="is-primary"
                      size="is-small"
                      @input="
                        searchStore.loadFilters();
                        handleFilterChange();
                      ">
                      <b-icon :icon="option.icon" pack="fal" size="is-small"></b-icon>
                      <span>{{ option.title }}</span>
                    </b-radio-button>
                  </div>
                </div>

                <!-- Filter -->
                <div class="hrbr-search__filter">
                  <b-field
                    class="hrbr-search__filter-field"
                    :class="{
                      'hrbr-search__filter-field--active': agreementIdFilter,
                    }">
                    <b-select
                      v-model="agreementIdFilter"
                      placeholder="Agreement"
                      :icon="agreementIdFilter ? 'check' : 'file-signature'"
                      icon-pack="fal"
                      :loading="!templatesStore.isGridReady"
                      expanded
                      size="is-small"
                      @input="handleFilterChange">
                      <option :value="null">All agreements</option>
                      <option
                        v-for="agreement in templates"
                        :value="agreement.agreement_unique_id"
                        :key="agreement.agreement_unique_id">
                        {{ agreement.title }}
                      </option>
                    </b-select>
                  </b-field>
                </div>

                <!-- Filter -->
                <div class="hrbr-search__filter">
                  <b-field
                    class="hrbr-search__filter-field"
                    :class="{
                      'hrbr-search__filter-field--active': agreementFolderIdFilter,
                    }">
                    <b-select
                      v-model="agreementFolderIdFilter"
                      placeholder="Folder"
                      :icon="agreementFolderIdFilter ? 'check' : 'folder'"
                      icon-pack="fal"
                      :loading="!harbourStore.isFolderDataReady"
                      expanded
                      size="is-small"
                      @input="handleFilterChange">
                      <option :value="null">All folders</option>
                      <option
                        v-for="folder in getSearchFoldersList()"
                        :value="folder.id"
                        :key="folder.id">
                        {{ folder.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>

                <!-- Filter -->
                <div class="hrbr-search__filter" v-if="!isOrgAdmin">
                  <b-field
                    class="hrbr-search__filter-field"
                    :class="{
                      'hrbr-search__filter-field--active': agreementCreatorEmailFilter,
                    }">
                    <b-select
                      v-model="agreementCreatorEmailFilter"
                      placeholder="Creator email"
                      :icon="agreementCreatorEmailFilter ? 'check' : 'at'"
                      icon-pack="fal"
                      expanded
                      size="is-small"
                      @input="handleFilterChange">
                      <option :value="null">All emails</option>
                      <option
                        v-for="email in searchStore.agreementCreatorEmails"
                        :value="email"
                        :key="email">
                        {{ email }}
                      </option>
                    </b-select>
                  </b-field>
                </div>

                <!-- Filter -->
                <div class="hrbr-search__filter">
                  <b-datepicker
                    class="hrbr-search__datepicker"
                    :class="{
                      'hrbr-search__datepicker--active': agreementCreateDatesFilter,
                    }"
                    v-model="agreementCreateDatesFilter"
                    placeholder="Complete date"
                    editable
                    range
                    expanded
                    size="is-small"
                    icon-right-clickable
                    icon-pack="fal"
                    :icon="agreementCreateDatesFilter ? 'check' : 'calendar-alt'"
                    :icon-right="agreementCreateDatesFilter ? 'close-circle' : ''"
                    @icon-right-click.stop="
                      agreementCreateDatesFilter = null;
                      handleFilterChange();
                    "
                    @input="handleFilterChange">
                  </b-datepicker>
                </div>

                <!-- More filters -->
                <div class="hrbr-search__filter hrbr-search__filter--more-filters">
                  <b-button
                    class="hrbr-search__more-filters button is-outline"
                    @click="handleMoreFiltersBtnClick"
                    size="is-small">
                    <b-icon pack="fal" icon="sliders-h"></b-icon>
                    <span>More filters</span>
                  </b-button>
                </div>
              </div>
            </template>

            <template slot-scope="props">
              <div class="hrbr-search__result-item">
                <div
                  v-if="isAdminMode && getFirstCommandIdx() === props.index"
                  class="hrbr-search__result-section-title"
                >
                  Harbour admin
                </div>
                <div
                  v-else-if="checkCommand(props.option.type) && getFirstCommandIdx() === props.index"
                  class="hrbr-search__result-section-title">
                  Quick actions
                </div>
                <div
                  v-else-if="
                    isRecentResults &&
                    getFirstResultItemIdx() === props.index &&
                    !checkCommand(props.option.type)
                  "
                  class="hrbr-search__result-section-title">
                  Recently added items
                </div>
                <div
                  v-else-if="
                    !isRecentResults &&
                    getFirstResultItemIdx() === props.index &&
                    !checkCommand(props.option.type)
                  "
                  class="hrbr-search__result-section-title">
                  Search results
                </div>

                <div
                  class="hrbr-search__result-media media"
                  :class="{
                    'hrbr-search__result-media--command': checkCommand(props.option.type),
                  }">
                  <div class="media-left" v-if="checkCommand(props.option.type)">
                    <div class="hrbr-search__preview-command">
                      <b-icon
                        class="hrbr-search__preview-icon"
                        :icon="props.option.icon"
                        pack="fal"></b-icon>
                    </div>
                  </div>
                  <div class="media-left" v-else>
                    <div
                      style="width: 50px; height: 50px"
                      v-if="mediasupportedimagetypes.includes(props.option.type)">
                      <img
                        loading="lazy"
                        width="50px"
                        height="50px"
                        class="hrbr-search__preview-img"
                        :src="'/previewimg?id=' + props.option.id"
                        @error="handleImgPreviewError" />
                    </div>
                    <div
                      v-else-if="mediasupportedpostscriptdocumenttypes.includes(props.option.type)">
                      <img
                        loading="lazy"
                        width="50px"
                        height="50px"
                        class="hrbr-search__preview-img"
                        :src="'/previewimg?id=' + props.option.id"
                        @error="handleImgPreviewError" />
                    </div>
                    <!-- folder -->
                    <div
                      class="hrbr-search__preview-folder"
                      v-else-if="props.option.type == 'FOLDER'">
                      <b-icon class="hrbr-search__preview-icon" pack="fal" icon="folder"></b-icon>
                    </div>
                    <!-- audio -->
                    <div
                      class="hrbr-search__preview-knowntype"
                      v-else-if="mediasupportedaudiotypes.includes(props.option.type)">
                      <b-icon
                        class="hrbr-search__preview-icon"
                        pack="fal"
                        icon="volume-up"></b-icon>
                    </div>
                    <!-- video -->
                    <picture v-else-if="mediasupportedvideotypes.includes(props.option.type)">
                      <img
                        loading="lazy"
                        width="50px"
                        height="50px"
                        class="hrbr-search__preview-img"
                        :src="'/previewimg?id=' + props.option.id"
                        @error="handleImgPreviewError" />
                    </picture>
                    <!-- document -->
                    <div
                      class="hrbr-search__preview-knowntype"
                      v-else-if="mediasupportedbinarydocumenttypes.includes(props.option.type)">
                      <b-icon class="hrbr-search__preview-icon" pack="fal" icon="file-alt"></b-icon>
                    </div>
                    <!-- unknown/other (*shows extension) -->
                    <div class="hrbr-search__preview-unknowntype" v-else>
                      <b-icon
                        class="hrbr-search__preview-icon"
                        pack="fal"
                        icon="search-plus"></b-icon>
                      <br />
                      <span class="hrbr-search__preview-unknowntype-text">{{
                        props.option.type
                      }}</span>
                    </div>
                  </div>

                  <div class="media-content">
                    {{ props.option.title }}
                    <span v-if="!checkCommand(props.option.type)">
                      <br />
                      <small>
                        <span
                          class="hrbr-search__folder-pills"
                          v-show="
                            props.option.parentfoldername != 'Home' &&
                            props.option.parentfoldername != 'Shared'
                          "
                          >{{ props.option.destinationparentfoldername }} ></span
                        >
                        <span class="hrbr-search__folder-pills">{{
                          props.option.parentfoldername
                        }}</span>
                      </small>
                      <br />
                      <small>
                        <HrbrSearchMatch :searchMatchList="props.option.matchscoreinfolist" />
                      </small>
                    </span>
                  </div>
                </div>
              </div>
            </template>

            <!-- <template slot="empty">
              <span v-html="searchResultsMsg" v-show="searchResultsMsg !== null"></span>
            </template> -->

            <template slot="footer">
              <div class="hrbr-search__autocomplete-footer">
                <div class="hrbr-search__results-message">
                  <div
                    class="hrbr-search__results-message-text"
                    v-show="searchResultsMsg !== null"
                    v-html="searchResultsMsg"></div>
                </div>

                <b-button
                  class="hrbr-search__show-agreements button is-primary"
                  size="is-small"
                  :disabled="totalMatchCount === 0"
                  @click="handleShowAgreementsBtnClick">
                  {{ showAgreementsButtonText }}
                </b-button>
              </div>
            </template>
          </b-autocomplete>

          <b-tooltip
            class="hrbr-search__tooltip"
            label="Search by uploaded file"
            position="is-bottom"
            :append-to-body="true">
            <b-button
              class="hrbr-search__file-search"
              :class="{ 'hrbr-search__file-search--focus': isOnFocus }"
              type="is-light"
              size="is-medium"
              @click="searchByFile">
              <b-icon pack="fal" icon="file-search"></b-icon>
            </b-button>
          </b-tooltip>

          <b-tooltip
            class="hrbr-search__tooltip"
            label="Search by document text or inputs"
            position="is-bottom"
            :append-to-body="true">
            <b-button
              class="hrbr-search__inputs-search"
              :class="{ 'hrbr-search__inputs-search--focus': isOnFocus }"
              type="is-light"
              size="is-medium"
              @click="handleInputsSearchBtnClick">
              <b-icon pack="fal" icon="sliders-h"></b-icon>
            </b-button>
          </b-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-search-modal {
  --button-filters-width: 72px;

  position: fixed;
  z-index: 40;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(255, 255, 255, 0.5);
  -webkit-font-smoothing: antialiased;
  text-align: left;
  color: rgb(60, 65, 73);
  font-family: inherit;

  &__content {
    position: relative;
    top: 15%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 0;
    background: #fff;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 50%) 0px 16px 70px;
    max-width: 760px;
    /* overflow: hidden; */
  }
}

.hrbr-search {
  display: flex;
  flex-direction: column;

  &__wrapper {
    display: flex;
    width: 100%;
    padding: 10px;
  }

  &__autocomplete {
    flex: 1;

    :deep(& > .control > .input) {
      font-size: 18px;
      color: rgb(34, 34, 34);
      width: 100%;
      height: 50px;
      padding-left: 35px;
      padding-right: 12px;
      background-color: #fff;
      background-image: url('../../assets/icons/search.svg');
      background-repeat: no-repeat;
      background-size: 22px 22px;
      background-position: 7px 14px;
      border: 1px solid #37373714;
      border-radius: 4px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
      transition: background 0.4s, box-shadow 0.2s;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      box-shadow: none;
      outline: none;

      &:focus {
        border-color: #19629345;
      }
    }

    :deep(& > .control > .icon) {
      font-size: 20px;
      color: #4a4a4a;
      height: 50px;
    }

    :deep(& > .dropdown-menu) {
      width: calc(100% + var(--button-filters-width));
    }

    :deep(& > .dropdown-menu > .dropdown-content) {
      max-height: 400px;
      overflow: auto;
      padding-top: 0;
      padding-bottom: 0;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  :deep(.dropdown-header) {
    position: sticky;
    top: 0;
    width: 100%;
    background: #fff;
    padding-top: 8px;
    z-index: 2;
  }

  :deep(.dropdown-footer) {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding-bottom: 8px;
    z-index: 1;
  }

  &__file-search {
    &.button {
      height: 50px;
      padding-left: 12px;
      padding-right: 12px;
      background-color: #fff;
      border: 1px solid #37373714;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      box-shadow: none;
    }

    &--focus {
      &.button {
        color: #222;
        border-color: #19629345;
      }
    }
  }

  &__inputs-search {
    &.button {
      height: 50px;
      padding-left: 16px;
      padding-right: 16px;
      background-color: #fff;
      border: 1px solid #37373714;
      border-radius: 4px;
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: none;
    }

    &--focus {
      &.button {
        color: #222;
        border-color: #19629345;
      }
    }
  }

  &__autocomplete-header {
    display: grid;
    grid-template-columns: repeat(4, 1fr) auto;
    grid-gap: 6px;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #dbdbdb;
  }

  &__autocomplete-footer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  &__filter {
    :deep(.control.has-icons-left .input),
    :deep(.control.has-icons-left .select select) {
      padding-left: 30px;
    }
  }

  &__view-type-radio-btn {
    &--default {
      :deep(.button) {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &--admin {
      :deep(.button) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &__datepicker {
    &--active {
      :deep(.control .input) {
        background-color: rgba(68, 151, 224, 0.66);
        color: #2d6396;
      }
      :deep(.control.has-icons-left .icon) {
        color: #2d6396;
      }
      :deep(.control.has-icons-left .select.is-small) {
        color: #2d6396;
      }
    }

    :deep(.dropdown-content) {
      position: fixed;
    }
    :deep(.datepicker-table .datepicker-body .datepicker-cell.is-selected) {
      background-color: #2d71ad;
      color: #fff;
    }
    :deep(.datepicker-table .datepicker-body .datepicker-cell.is-selected.is-first-selected) {
      background-color: #2d71ad;
      color: #fff;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    :deep(.datepicker-table .datepicker-body .datepicker-cell.is-selected.is-last-selected) {
      background-color: #2d71ad;
      color: #fff;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
    :deep(.datepicker-table .datepicker-body .datepicker-cell.is-selected.is-within-selected) {
      background-color: rgba(45, 113, 173, 0.5);
      border-radius: 0;
    }
    :deep(.datepicker-table .datepicker-body .datepicker-cell.is-today) {
      border: solid 1px rgba(45, 113, 173, 0.5);
    }
    :deep(.datepicker-table .datepicker-body .datepicker-cell.is-today) {
      border: solid 1px rgba(45, 113, 173, 0.5);
    }
    /* prettier-ignore */
    :deep(.datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-primary) {
      background-color: #2d71ad;
    }
    /* prettier-ignore */
    :deep(.datepicker-table .datepicker-body.has-events .datepicker-cell.has-event .events .event.is-link) {
      background-color: #2d71ad;
    }
    /* prettier-ignore */
    :deep(.datepicker-table .datepicker-body.has-events .datepicker-cell.is-selected .events .event.is-primary) {
      background-color: rgba(45, 113, 173, 0.22);
    }
    :deep(.dropdown-trigger .input) {
      border-radius: 4px;
    }
    :deep(.dropdown-trigger .input[readonly]:focus) {
      box-shadow: inset 0 0.0625em 0.125em rgba(45, 113, 173, 0.5);
    }
    :deep(.dropdown .input[readonly].is-active),
    :deep(.dropdown .input[readonly].is-focused),
    :deep(.dropdown .input[readonly]:active),
    :deep(.dropdown .input[readonly]:focus),
    :deep(.dropdown-trigger .input[readonly].is-active),
    :deep(.dropdown-trigger .input[readonly].is-focused),
    :deep(.dropdown-trigger .input[readonly]:active),
    :deep(.dropdown-trigger .input[readonly]:focus) {
      box-shadow: 0 0 0 0.125em rgba(45, 113, 173, 0.25);
    }
    :deep(.icon.is-right .fa-times-circle) {
      font-size: inherit;
      color: inherit;
      padding: 0;
    }
    :deep(.has-text-primary) {
      color: #2d71ad !important;
    }
  }

  &__filter-field {
    &--active {
      :deep(.control.has-icons-left .icon) {
        color: #2d6396;
      }
      :deep(.control.has-icons-left .select select) {
        background-color: rgba(68, 151, 224, 0.66);
        color: #2d6396;
      }
    }
  }

  &__more-filters {
    &.button {
      border-radius: 4px;
    }
  }

  &__show-agreements {
    &.button {
      border-radius: 4px;
    }
  }

  &__results-message {
    display: flex;
    align-items: center;
    user-select: none;
    min-width: 0;
  }

  &__results-message-text {
    font-size: 14px;
    color: #4a4a4a;
    line-height: 1.2;
    opacity: 0.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__result-item {
    line-height: 1.25;

    small {
      font-size: 11px;
    }
  }

  &__result-media {
    &--command {
      align-items: center;
    }
  }

  &__result-section-title {
    font-size: 14px;
    margin-bottom: 4px;
    color: #00000085;
  }

  &__preview-img {
    --size: 50px;

    background-repeat: no-repeat;
    background-position: center center;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
    object-fit: contain;
    background-color: white;
  }

  &__preview-folder {
    --size: 50px;

    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
    display: inline-block;
    padding-left: 15px;
    padding-top: 8px;

    i {
      font-size: 33px;
      color: #797979;
    }
  }

  &__preview-knowntype {
    --size: 50px;

    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
    display: inline-block;
    padding-left: 15px;
    padding-top: 8px;
    margin-top: 3px;

    i {
      font-size: 33px;
      color: #2d71ad;
    }
  }

  &__preview-unknowntype {
    --size: 50px;

    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
    display: inline-block;
    padding-left: 15px;
    padding-top: 8px;

    i {
      color: #797979;
      font-size: 23px;
      line-height: 28px;
    }
  }

  &__preview-unknowntype-text {
    font-size: 12px;
    color: #0000008c;
  }

  &__preview-command {
    --width: 50px;
    --height: 24px;

    width: var(--width);
    height: var(--height);
    min-width: var(--width);
    min-height: var(--height);
    padding-left: 15px;
  }

  &__folder-pills {
    font-size: 12px;
    color: #767676;
  }
}

.bump {
  animation: zoom-in-zoom-out 0.2s ease;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.99);
  }
  50% {
    transform: scale(1.01, 1.01);
  }
  100% {
    transform: scale(1, 1);
  }
}

@media only screen and (max-width: 1023px) {
  .hrbr-search {
    &__datepicker {
      :deep(.dropdown-content) {
        position: static;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .hrbr-search {
    &__autocomplete-header {
      grid-template-columns: repeat(2, 1fr);
    }

    &__filter {
      margin-bottom: 0;

      &--more-filters {
        grid-column: 1 / -1;
      }
    }

    &__more-filters {
      width: 100%;
    }

    &__autocomplete {
      :deep(& > .dropdown-menu > .dropdown-content) {
        max-height: 420px;
      }
    }

    &__view-type-radio-wrap {
      flex: 1;
    }
  }
}
</style>

<style lang="postcss">
/* global styles */
.hrbr-search {
  &__tooltip {
    &.b-tooltip.is-primary.is-medium .tooltip-content {
      background: #2d71ad;

      &:before {
        border-top-color: #2d71ad;
        border-bottom-color: #2d71ad;
      }
    }
  }
}
</style>
