<script setup>
import HrbrInput from '@/components/ui/HrbrInput.vue';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import internalToolsService from '@/services/internal-tools-service.js';
import templateApiService from '@/services/templates-api-service';
import { jsonArrayGroupBy, sortObjectByKeys } from '@/utils/helpers/collection-methods.js';
import { ToastProgrammatic as Toast } from 'buefy';
import { computed, onMounted, reactive, ref } from 'vue';

const templateStore = useTemplatesStore();

const emit = defineEmits(['close']);

const isTemplateSelectOpened = ref(false);
const selectedTemplate = ref({});

const isTargetOrgTemplateGroupSelectOpened = ref(false);
const selectedTargetOrgTemplateGroup = ref({});

const groupedTemplates = ref([]);
const targetOrgId = ref('');
let targetOrgTemplateGroups = ref([]);

const isLoadingTemplateGroups = ref(false);
const areTemplateGroupsAvailable = ref(false);

const isConfirmDisabled = computed(() => {
  return (
    !selectedTemplate.value.agreement_id ||
    !targetOrgId.value ||
    !selectedTargetOrgTemplateGroup.value.groupId ||
    isLoadingTemplateGroups.value
  );
});

const cancelButton = reactive({
  show: true,
  text: 'Cancel',
  action: () => {
    emit('close');
  },
});

const confirmButton = computed(() => ({
  show: true,
  text: 'Confirm',
  styleType: 'primary',
  disabled: isConfirmDisabled.value,
  action: () => {
    if (!isConfirmDisabled.value) {
      copyTemplate();
    }
  },
}));

const getTemplateGroupName = (groupId) => {
  const group = templateStore.templatesGroups.find((t) => t.groupId === groupId);
  if (group) {
    return group.groupName;
  } else return '-';
};

const selectTemplate = (template) => {
  selectedTemplate.value = template;
  isTemplateSelectOpened.value = false;
};

const selectTargetOrgTemplateGroup = (templateGroup) => {
  selectedTargetOrgTemplateGroup.value = templateGroup;
  isTargetOrgTemplateGroupSelectOpened.value = false;
};

const copyTemplate = async () => {
  try {
    await internalToolsService.copyTemplateToOrg(selectedTemplate.value.agreement_id, {
      createdBy: selectedTemplate.value.original_creator_email,
      orgId: targetOrgId.value,
      targetOrgTemplateGroupId: selectedTargetOrgTemplateGroup.value.groupId,
    });
    Toast.open({
      message: 'Template was successfully copied!',
      type: 'is-success',
      position: 'is-top',
      duration: 3500,
    });
  } catch (e) {
    console.error(e);
    Toast.open({
      message: `Unable to copy template - ${e.message}`,
      type: 'is-danger',
      position: 'is-top',
      duration: 3500,
    });
  }
};

const byGroupName = (a, b) => {
  const aTitle = a.groupName.toLowerCase();
  const bTitle = b.groupName.toLowerCase();
  return aTitle < bTitle ? -1 : Number(aTitle > bTitle);
};

const loadTargetTemplateGroups = async () => {
  isLoadingTemplateGroups.value = true;
  areTemplateGroupsAvailable.value = false;
  selectedTargetOrgTemplateGroup.value = {}; // Reset selected group
  try {
    const data = await templateApiService.getTemplatesGroups({
      organization_id: targetOrgId.value,
    });
    targetOrgTemplateGroups.value = data?.templateGroupsList || [];
    targetOrgTemplateGroups.value?.sort(byGroupName);

    if (targetOrgTemplateGroups.value.length === 0) {
      Toast.open({
        message: 'No template groups found for the given organization ID.',
        type: 'is-warning',
        position: 'is-top',
        duration: 3500,
      });
    } else {
      areTemplateGroupsAvailable.value = true;
    }
  } catch (error) {
    console.error('Error loading template groups:', error);
    Toast.open({
      message: 'Failed to load template groups',
      type: 'is-danger',
      position: 'is-top',
      duration: 3500,
    });
  } finally {
    isLoadingTemplateGroups.value = false;
  }
};

onMounted(() => {
  const templateGroups = jsonArrayGroupBy(templateStore.myTemplates, (c) => c.template_group_id);
  groupedTemplates.value = sortObjectByKeys(templateGroups);
});
</script>

<template>
  <div class="copy-template-modal hrbr-modal modal-card">
    <header class="modal-card-head">
      <h3 class="modal-card-title">Copy template to organization</h3>
    </header>
    <section class="modal-card-body">
      <div class="select-group">
        <p class="label">Select template</p>
        <div class="selected" @click="isTemplateSelectOpened = !isTemplateSelectOpened">
          <div class="selected-data">
            <span>{{ selectedTemplate.title || 'Select a template' }}</span>
          </div>
          <i v-if="!isTemplateSelectOpened" class="fa-solid fa-caret-down"></i>
          <i v-if="isTemplateSelectOpened" class="fa-solid fa-caret-up"></i>
        </div>
        <div v-if="isTemplateSelectOpened" class="dropdown">
          <div
            v-for="(groupedTemplates, groupId) in groupedTemplates"
            :key="groupId"
            class="dropdown-group">
            <p class="group-title">{{ getTemplateGroupName(groupId) }}</p>
            <div
              v-for="template in groupedTemplates"
              :key="template.agreement_id"
              :class="[
                'dropdown-item',
                template.agreement_id === selectedTemplate.agreement_id && 'selected',
              ]"
              @click="selectTemplate(template)">
              {{ template.title }}
            </div>
          </div>
        </div>
      </div>

      <hrbr-input
        v-model="targetOrgId"
        label="Enter target org ID"
        @blur="loadTargetTemplateGroups()" />

      <div class="select-group">
        <p class="label">Select target template group</p>
        <div
          class="selected"
          :class="{
            disabled: isLoadingTemplateGroups || !targetOrgId || !areTemplateGroupsAvailable,
          }"
          @click="
            !isLoadingTemplateGroups &&
              targetOrgId &&
              areTemplateGroupsAvailable &&
              (isTargetOrgTemplateGroupSelectOpened = !isTargetOrgTemplateGroupSelectOpened)
          ">
          <div class="selected-data">
            <span v-if="!isLoadingTemplateGroups && targetOrgId && areTemplateGroupsAvailable">{{
              selectedTargetOrgTemplateGroup.groupName || 'Select a group'
            }}</span>
            <span v-else-if="isLoadingTemplateGroups">Loading...</span>
            <span v-else-if="!targetOrgId">Enter target org ID first</span>
            <span v-else>No groups available</span>
          </div>
          <i
            v-if="!isLoadingTemplateGroups && targetOrgId && areTemplateGroupsAvailable"
            :class="[
              'fa-solid',
              isTargetOrgTemplateGroupSelectOpened ? 'fa-caret-up' : 'fa-caret-down',
            ]"></i>
          <i v-else-if="isLoadingTemplateGroups" class="fa-solid fa-spinner fa-spin"></i>
        </div>
        <div
          v-if="
            isTargetOrgTemplateGroupSelectOpened &&
            !isLoadingTemplateGroups &&
            targetOrgId &&
            areTemplateGroupsAvailable
          "
          class="dropdown">
          <div
            v-for="targetTemplateGroup in targetOrgTemplateGroups"
            :key="targetTemplateGroup.groupId"
            :class="[
              'dropdown-item',
              targetTemplateGroup.groupId === selectedTargetOrgTemplateGroup.groupId && 'selected',
            ]"
            @click="selectTargetOrgTemplateGroup(targetTemplateGroup)">
            {{ targetTemplateGroup.groupName }}
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <HrbrButton class-name="cancel-btn" :button="cancelButton" />
      <HrbrButton class-name="confirm-btn" :button="confirmButton" />
    </footer>
  </div>
</template>

<style lang="postcss" scoped>
@import '@/assets/css/elements/modal.css';
@import '@/assets/css/elements/select.css';

.select-group {
  .label {
    color: #49525c;
    margin-bottom: 0.5rem;
  }
  > .selected,
  .dropdown {
    border-radius: 5px;
    &:hover {
      border-color: rgba(47, 90, 137, 0.6);
    }
  }
  > .selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #fff;
    border: 1px solid #d1d5db;
    cursor: pointer;

    &.disabled {
      background-color: #f3f4f6;
      border-color: #e5e7eb;
      color: #9ca3af;
      cursor: not-allowed;

      &:hover {
        border-color: #e5e7eb;
      }

      i {
        color: #9ca3af;
      }
    }

    .selected-data {
      flex-grow: 1;
    }

    i {
      margin-left: 0.5rem;
    }
  }
  .dropdown {
    border: 1px solid #d1d5db;
    margin-top: 0.25rem;
    max-height: 200px;
    overflow-y: auto;

    .dropdown-item {
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        background-color: #f3f4f6;
      }

      &.selected {
        background-color: #e5e7eb;
      }
    }
  }
}
</style>
