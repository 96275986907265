<script setup>
import { useWorkflowsStore } from '@/stores/workflows-store';
import { AgGridVue } from 'ag-grid-vue';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onActivated } from 'vue';
import { useSidebarStore } from './stores/sidebar-store';

const sidebarStore = useSidebarStore();
const workflowsStore = useWorkflowsStore();
const {
  isWorkflowsListReady,
  workflowGroups,
  activeWorkflowGroup,
  activeWorkflow,
  sidebarGridApi,
  sidebarGridColumnApi,
  groupNodeState
} = storeToRefs(workflowsStore);
const { filter } = storeToRefs(sidebarStore);

const getName = (groupId) => {
  const groupName = workflowGroups.value.find((group) => group.id === groupId);
  return groupName?.name;
};

const workflowsList = computed(() => {
  if (!isWorkflowsListReady.value) return [];
  const allWorkflows = [];

  workflowsStore.workflowGroups.forEach((workflow) => {
    // Add all workflows in this group
    const currentFlows = workflowsStore.myWorkflows || [];
    const workflowsInGroup = currentFlows.filter((wf) => wf.group === workflow.id);
    if (workflowsInGroup.length === 0) {
      allWorkflows.push({
        owner: 'All workflows',
        isOrgWide: workflow.is_org_wide,
        groupId: workflow.id,
        groupName: getName(workflow.id),
        emptyGroup: true,
      });
    }

    workflowsInGroup.forEach((wf) => {
      allWorkflows.push({
        owner: 'All workflows',
        isOrgWide: workflow.is_org_wide,
        groupId: workflow.id,
        groupName: getName(workflow.id),
        workflow: wf,
      });
    });
  });

  return allWorkflows;
});

const reOpenGroups = () => {
  if (!sidebarGridApi.value) return;
  nextTick(() => {
    sidebarGridApi.value?.forEachNode((node) => {
      if (node.key in groupNodeState.value) {
        if (groupNodeState.value[node.key]) node.setExpanded(true);
        else node.setExpanded(false);
      }
    });
    sidebarGridApi.value?.redrawRows();
  });
};

const onRowClicked = (e) => {
  activeWorkflowGroup.value = e.node.key;
  if (e.node.group) {
    const children = e.node.childrenAfterGroup;
    if  (!children) return;
    sidebarGridApi.value.setRowNodeExpanded(e.node, !e.node.expanded);
  } else {
    if (e.data.emptyGroup) return;
    workflowsStore.setActiveWorkflow(e.data.workflow);
  }
};

const onFilterChanged = (event) => {
  sidebarGridApi.value.collapseAll();
  setTimeout(() => sidebarGridApi.value.expandAll(), 300);
};

const handleRowDataUpdated = (e) => reOpenGroups();
const handleRowGroupChanged = (group) => {
  groupNodeState.value[group.node.key] = group.node.expanded;
};

const gridOptions = {
  rowBuffer: 4,
  rowHeight: 36,
  suppressContextMenu: true,
  groupDisplayType: 'groupRows',
  groupDefaultExpanded: 1,
  suppressRowClickSelection: true,
  suppressCellFocus: true,
  groupAllowUnbalanced: true,
  getRowClass: (params) => {
    if (params.data?.emptyGroup) return null
    else return 'non-empty-cell';
  },
  groupRowRendererParams: {
    innerRenderer: 'WorkflowsGroupRenderer',
    gridApi: sidebarGridApi.value,
    getRowId: (params) => params?.groupId,
    expandNode: (node) => {
      groupNodeState.value[node.key] = true
    },
    suppressCount: true,
  },
  onRowClicked,
  onFilterChanged,
  onRowGroupOpened: handleRowGroupChanged,
  onRowDataUpdated: handleRowDataUpdated,
};

const defaultColumns = {
  getQuickFilterText: (params) => {
    return [params.data.groupName, params.value];
  },
};
const columnDefs = [
  {
    field: 'owner',
    hide: true,
    rowGroup: true,
  },
  {
    field: 'groupId',
    rowGroup: true,
    hide: true,
    sort: 'asc',
    comparator: (a, b, nodeA, nodeB) => {
      const groupA = workflowGroups.value.find((group) => group.id === a);
      const groupB = workflowGroups.value.find((group) => group.id === b);

      // Org wide groups come first
      if (groupA.is_org_wide && !groupB.is_org_wide) return -1;
      if (!groupA.is_org_wide && groupB.is_org_wide) return 1;

      return groupA?.name.localeCompare(groupB?.name);
    }
  },
  {
    field: 'workflow',
    flex: 1,
    cellClass: 'workflow-menu-cell',
    cellRenderer: 'WorkflowMenuRenderer',
    valueGetter: (params) => {
      return params.data?.workflow?.name;
    },
    sort: 'asc',
    comparator: (a, b, nodeA, nodeB) => {
      return a?.localeCompare(b);
    },
  },
];

const gridContext = computed(() => {
  return {
    activeWorkflow,
    activeWorkflowGroup
  }
});

const onGridReady = (params) => {
  sidebarGridApi.value = params.api;
  sidebarGridColumnApi.value = params.columnApi;

  if (activeWorkflow.value) {
    groupNodeState.value[activeWorkflow.value.group] = true;
  }
  reOpenGroups();
};

onActivated(() => {
  reOpenGroups();
});
</script>

<template>
  <div class="hrbr-grid-container">
    <div class="hrbr-grid-container ag-theme-alpine workflows-menu-grid">
      <div class="show-loader" v-if="!isWorkflowsListReady">
        <i class="fa fa-spinner-third fa-spin"></i>
      </div>
      <ag-grid-vue
        v-else
        class="ag-theme-alpine workflows-menu-grid"
        style="width: 100%; height: 100%"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :rowData="workflowsList"
        :headerHeight="38"
        :animateRows="false"
        :quickFilterText="filter"
        :defaultColDef="defaultColumns"
        :context="gridContext"
        ref="gridReference"
        @grid-ready="onGridReady" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.show-loader {
  width: 100%;
  min-height: 200px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workflows-menu-grid {
  --ag-borders: none;
  --ag-row-group-indent-size: 1px;
  --ag-odd-row-background-color: white;
  :deep(.ag-row) {
    border-bottom: none;
  }
  :deep(.active-group) {
    border: 1px solid red;
  }
  :deep(.empty-cell) {
    cursor: default;
    pointer-events: none;
  }
  :deep(.ag-row-group) {
    border: none;
    overflow: visible !important;
    padding-right: 0 !important;
    &:not(.ag-cell-wrapper) {
      padding-left: 4px !important;
    }
  }
  :deep(.ag-full-width-focus) {
    border: none;
  }
  :deep(.ag-header) {
    display: none;
  }
  :deep(.workflow-menu-cell) {
    overflow: visible !important;
    padding-right: 0 !important;
  }
  :deep(.active-workflow) {
    background-color: #e8f4fe;
    border: 1px solid #2196f3;
  }
  :deep(.ag-cell-wrapper) {
    padding: 0;
    margin: 0;
    overflow: visible !important;
  }
  :deep(.ag-row-group-indent-0) {
    padding: 0 0 0 4px !important;
    margin: 0 !important;
  }
  :deep(.ag-group-value) {
    width: 100%;
    overflow: visible !important;
  }
  :deep(.workflow-group) {
    overflow: visible !important;
  }
  :deep(.dropdown-content) {
    background-color: red !important;
    position: absolute;
    z-index: 99999;
  }
}
</style>
